import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user-store.js'
import { useGlobalStore } from '@/stores/global-store.js'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: () => import('../views/ErrorNotFound.vue')
  },
  {
    path: '/',
    name: 'RootPage',
    redirect: { name: 'PanelDashboard' },
  },
  {
    path: '/panel',
    name: 'PanelMain',
    component: () => import(/* webpackChunkName: "panel" */ '../views/panel/PanelLayout.vue'),
    redirect: { name: 'PanelDashboard' },
    beforeEnter: async (to) => {
      const userStore = useUserStore()
      await userStore.waitForInit()
      if (!userStore.isLoggedIn) {
        return { path: '/logowanie', replace: true, query: { rfp: to.fullPath } }
      }
      return userStore.isLoggedIn
    },
    children: [
      {
        path: 'dashboard',
        name: 'PanelDashboard',
        component: () => import(/* webpackChunkName: "panel" */ '../views/panel/PanelDashboard.vue'),
        meta: { title: 'Moje formularze' }
      },
      {
        path: 'createForm',
        name: 'PanelFormCreate',
        component: () => import(/* webpackChunkName: "panel" */ '../views/panel/forms/FormCreate.vue'),
        meta: { title: 'Nowy formularz' }
      },
      {
        path: 'form/:id',
        name: 'PanelFormView',
        component: () => import(/* webpackChunkName: "panel" */ '../views/panel/forms/FormView.vue'),
        meta: { title: 'Podgląd formularza' }
      },
      {
        path: 'contact',
        name: 'PanelContact',
        component: () => import(/* webpackChunkName: "panel" */ '../views/panel/PanelContact.vue'),
        meta: { title: `Informacje kontaktowe` }
      },
      {
        path: 'faq',
        name: 'PanelFAQ',
        component: () => import(/* webpackChunkName: "panel" */ '../views/panel/PanelFAQ.vue'),
        meta: { title: `FAQ / Instrukcje` }
      }
    ]
  },
  {
    path: '/admin',
    name: 'AdminMain',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminLayout.vue'),
    redirect: { name: 'AdminDashboard' },
    beforeEnter: async (to) => {
      const userStore = useUserStore()
      await userStore.waitForInit()
      if (!userStore.isAdmin) {
        return { path: '/logowanie', replace: true, query: { rfp: to.fullPath } }
      }
      return userStore.isAdmin
    },
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminDashboard.vue'),
        meta: { title: `Lista zgłoszeń` }
      },
      {
        path: 'form/:id',
        name: 'AdminForm',
        component: () => import(/* webpackChunkName: "panel" */ '../views/admin/AdminForm.vue'),
        meta: { title: `Podgląd zgłoszenia` }
      },
      {
        path: 'profile',
        name: 'AdminProfile',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminProfile.vue'),
        meta: { title: `Profil administratora` }
      },
      {
        path: 'appInfo',
        name: 'AdminAppInfo',
        component: () => import(/* webpackChunkName: "panel" */ '../views/admin/AdminAppInfo.vue'),
        meta: { title: `Informacje o aplikacji` }
      },
    ]
  },
  {
    path: '/superadmin',
    name: 'SAMain',
    component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SALayout.vue'),
    redirect: { name: 'SADashboard' },
    beforeEnter: async (to) => {
      const userStore = useUserStore()
      await userStore.waitForInit()
      if (!userStore.isSuperAdmin) {
        return { path: '/logowanie', replace: true, query: { rfp: to.fullPath } }
      }
      return userStore.isSuperAdmin
    },
    children: [
      {
        path: 'dashboard',
        name: 'SADashboard',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SADashboard.vue')
      },
      {
        path: 'faculties',
        name: 'SAFacultiesRoot',
        meta: { title: `Jednostki` },
        children: [
          {
            name: 'SAFaculties',
            path: '',
            component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFaculties.vue'),
          },
          {
            path: ':id/permissions',
            name: 'SAFactultyPermissions',
            component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFacultyPermissions.vue'),
            meta: { title: `Uprawnienia jednostki` }
          }
        ]
      },
      {
        path: 'formCategories',
        name: 'SAFormCategories',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFormCategories.vue'),
        meta: { title: `Kategorie formularzy` }
      },
      {
        path: 'users',
        name: 'SAUsers',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAUsers.vue'),
        meta: { title: `Użytkownicy` }
      },
      {
        path: 'logs',
        name: 'SALogs',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SALogs.vue'),
        meta: { title: `Logi` }
      },
      {
        path: 'mails',
        name: 'SAMails',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAMails.vue'),
        meta: { title: `Logi` }
      },
      {
        path: 'faq',
        name: 'SAFAQ',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFAQ.vue'),
        meta: { title: `Konfiguracja FAQ` }
      },
      {
        path: 'files',
        name: 'SAFiles',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFiles.vue'),
        meta: { title: `Pliki administracyjne` }
      },
      {
        path: 'fileSystemVerification',
        name: 'SAFileSystemVerification',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAFileSystemVerification.vue'),
        meta: { title: `Weryfikacja plików` }
      },
      {
        path: 'extAuth',
        name: 'SAExtAuth',
        component: () => import(/* webpackChunkName: "superadmin" */ '../views/superadmin/SAExtAuth.vue'),
        meta: { title: `Zewnętrzne uwierzytelnianie` }
      }
    ]
  },
  {
    path: '/logowanie',
    name: 'CASLogin',
    component: () => import(/* webpackChunkName: "auth" */ '../views/CASLogin.vue'),
    meta: { title: `Logowanie do systemu` }
  },
  {
    path: '/logout',
    name: 'CASLogout',
    component: () => import(/* webpackChunkName: "auth" */ '../views/CASLogout.vue'),
    meta: { title: `Wylogowywanie z systemu` }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const defaultTitle = `System Publikacji Aktualności`
  const routeTitle = to.meta?.title ? `${to.meta?.title} | ${defaultTitle}` : null
  const title = routeTitle || defaultTitle

  document.title = title

  const globalStore = useGlobalStore()
  globalStore.routerLoadingState = true
})

router.afterEach(() => {
  const globalStore = useGlobalStore()
  globalStore.routerLoadingState = false
})

export default router
